
import { i18n } from '@/i18n';
import HomeHotNews from '@/modules/home/components/1-home-hot-news.vue';
import HomeLatestNews from '@/modules/home/components/2-home-latest-news.vue';
import HomeSports from '@/modules/home/components/3-home-sports.vue';
import HomeMatches from '@/modules/home/components/4-home-matches.vue';
import HomeFirstTeam from '@/modules/home/components/5-home-first-team.vue';

// import HomeFirstTeam from '@/modules/home/components/home-first-team-page.vue';
import HomeShop from '@/modules/home/components/6-home-shop.vue';
import HomeMobileApp from '@/modules/home/components/7-home-mobile-app.vue';
import HomeMedia from '@/modules/home/components/8-home-media.vue';

import { mapActions, mapGetters } from 'vuex';
import { finished } from 'stream';
// import firebase from 'firebase/app';
// import 'firebase/firestore'

export default {
  name: 'app-home-page',

  components: {
    [HomeHotNews.name]: HomeHotNews,
    [HomeLatestNews.name]: HomeLatestNews,
    [HomeSports.name]: HomeSports,
    [HomeMatches.name]: HomeMatches,
    [HomeFirstTeam.name]: HomeFirstTeam,
    [HomeShop.name]: HomeShop,
    [HomeMobileApp.name]: HomeMobileApp,
    [HomeMedia.name]: HomeMedia,
  },

  created() {

    // const filter = { status: 'inStock' }
    // this.doFetch({ filter });
    // setTimeout(() => {
    //   this.notFirst = true
    // }, 1000);
    // this.unsubscribe = firebase.firestore().collection('items').onSnapshot({ includeMetadataChanges: true }, async snap => {
    //   console.log('snap meta' , snap.metadata.fromCache?'cache':'server');
    //   if(this.notFirst){
    //     this.doFetch({ filter });
    //   }
    // })

    if (this.isMobile) {
      this.collapseMenu();
    }
  },
  // beforeDestroy(){
  //   if(this.unsubscribe) this.unsubscribe()
  // },
  data() {
    return {
      isOpen: false,
      data: {
        type: "ads"
      },
      error: false,
      errorMessage: "",
      finished: false,

      // filterMonth: 0,
      // notFirst:false,
      // unsubscribe:undefined
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },

  methods: {

    isFormValid() {
      for (var key in this.data) {
        var value = this.data[key]
        for (var groupKey in value) {
          var value2 = value[groupKey]
          if (value2.length == 0) {
            this.errorMessage = 'Please Fill all the Fields'
            return false
          }
        }
      }
      if (!this.validEmail(this.data.email)) {
        this.errorMessage = 'Please enter a valid Email'
        return false
      }
      console.log(this.data)
      return true
    },
    validEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },

    async doSubmit() {
      if (!this.isFormValid()) {
        this.error = true
      } else {
        this.error = false
        await this.doCreateContact(this.data)
        this.finished = true
        this.data = {}
      }

    },

    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
      doCreateContact: 'layout/doCreateContact'

    }),
    i18n(code) {
      return i18n(code);
    },
    toggleModal() {
      this.isOpen = !this.isOpen
    }
    // setFilterMonth(month){
    //   this.filterMonth = month
    // }
  },
};
