
import { i18n } from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
import { NewsModel } from '@/modules/news/news-model';
import moment from 'moment';
// import firebase from 'firebase/app';
// import 'firebase/auth'; // for authentication

export default {
  name: 'app-home-hot-news-page',

  data() {
    return {
      // token: null,
      firstImage: '',
      currentHoveredElement: '',
    };
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      news: 'news/list/rows',
      mountedTable: 'news/list/mountedTable',
      loading: 'news/list/loading',
      token: 'layout/token',
    }),
    trimedNews() {
      return this.news.slice(0, 3);
    },
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
      doFetch: 'news/list/doFetch',
      setMountedTable: 'news/list/setMountedTable',
      setToken: 'layout/setToken',
    }),
    i18n(code) {
      return i18n(code);
    },

    displayDate(date) {
      return date
        ? new Date(date).toLocaleString(
            `${this.isRTL ? 'ar-EG' : `en-US`}`,
            {
              timeZone: 'Africa/Cairo',
            },
          )
        : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    },

    presenterTime(row, fieldName) {
      let date = NewsModel.presenter(row, fieldName);
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('dddddd D MMM YYYY hh:mm:ss A')
        : 'ـــ';
    },
    hoverArticle(elementId) {
      this.firstImage = this.news[0].id;
      const firstArticle = document.getElementById(
        this.news[0].id,
      );
      const secondArticle = document.getElementById(
        this.news[1].id,
      );
      const thirdArticle = document.getElementById(
        this.news[2].id,
      );
      if (elementId == this.news[0].id) {
        this.currentHoveredElement = elementId;
        firstArticle.classList.add('is-active');
        secondArticle.classList.remove('is-active');
        thirdArticle.classList.remove('is-active');
      } else if (elementId == this.news[1].id) {
        this.currentHoveredElement = elementId;
        firstArticle.classList.remove('is-active');
        secondArticle.classList.add('is-active');
        thirdArticle.classList.remove('is-active');
      } else if (elementId == this.news[2].id) {
        this.currentHoveredElement = elementId;
        firstArticle.classList.remove('is-active');
        secondArticle.classList.remove('is-active');
        thirdArticle.classList.add('is-active');
      } else {
        firstArticle.classList.remove('is-active');
        secondArticle.classList.remove('is-active');
        thirdArticle.classList.add('is-active');
      }
      // else{

      // }

      // if(elementId == this.firstImage){
      //   console.log('first element hovered');
      // firstArticle.classList.add("is-active");

      // }
      // else{
      //   console.log('not hovered');
      //   firstArticle.classList.remove("is-active");
      // }
    },
    hoverFirstArticle() {
      // const firstArticle = document.getElementById(this.news[0].id)
      // const secondArticle = document.getElementById(this.news[1].id)
      // const thirdArticle = document.getElementById(this.news[2].id)
      // if(this.currentHoveredElement == this.news[0].id){
      //   firstArticle.classList.add("is-active");
      //   secondArticle.classList.remove("is-active");
      //   thirdArticle.classList.remove("is-active");
      // }
      // else if(this.currentHoveredElement == this.news[1].id){
      //   firstArticle.classList.remove("is-active");
      //   secondArticle.classList.add("is-active");
      //   thirdArticle.classList.remove("is-active");
      // }
      // else if(this.currentHoveredElement == this.news[2].id){
      //   firstArticle.classList.remove("is-active");
      //   secondArticle.classList.remove("is-active");
      //   thirdArticle.classList.add("is-active");
      // }
      // else {
      //    firstArticle.classList.remove("is-active");
      //   secondArticle.classList.remove("is-active");
      //   thirdArticle.classList.add("is-active");
      // }
    },
  },
  created() {
    this.doFetch({
      filterArray: [],
      action: 'next',
      currentPage: 0,
    });
  },
};
