
import { i18n } from '@/i18n';

import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import AdSpace from '@/modules/home/components/ad-space.vue';

// import firebase from 'firebase/app';
// import 'firebase/firestore'

export default {
  name: 'app-home-media-page',
  components: {
    [AdSpace.name]: AdSpace,
  },

  data() {
    return {
      mainVideo: null,
      mainImages: [],
    };
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      rows: 'media/view/rows',
    }),

    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
  },
  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
      doFetch: 'media/view/doFetch',
    }),
    i18n(code) {
      return i18n(code);
    },
    displayDate(date) {
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('D MMM, YYYY')
        : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    },
  },

  async created() {
    await this.doFetch({
      action: 'next',
      page: 0,
    });

    if (this.rows.length > 0) {
      this.mainVideo = this.rows.find(
        (e) => e.videos.length > 0,
      );
    }
    if (this.rows.length > 0) {
      this.mainImages = this.rows.find(
        (e) => e.images.length > 1,
      );
    }

    if (this.isMobile) {
      this.collapseMenu();
    }
  },
};
