
import { i18n } from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
// import firebase from 'firebase/app';
// import 'firebase/firestore'

export default {
  name: 'app-home-mobile-app-page',

  created() {
    // const filter = { status: 'inStock' }
    // this.doFetch({ filter });
    // setTimeout(() => {
    //   this.notFirst = true
    // }, 1000);
    // this.unsubscribe = firebase.firestore().collection('items').onSnapshot({ includeMetadataChanges: true }, async snap => {
    //   console.log('snap meta' , snap.metadata.fromCache?'cache':'server');
    //   if(this.notFirst){
    //     this.doFetch({ filter });
    //   }
    // })

    if (this.isMobile) {
      this.collapseMenu();
    }
  },
  // beforeDestroy(){
  //   if(this.unsubscribe) this.unsubscribe()
  // },
  data() {
    return {
      // filterMonth: 0,
      // notFirst:false,
      // unsubscribe:undefined
    };
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
  },
  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
    }),
    i18n(code) {
      return i18n(code);
    },
  },
};
