
import { i18n } from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
// import firebase from 'firebase/app';
// import 'firebase/firestore'

export default {
  name: 'app-home-latest-news-page',

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      news: 'news/list/rows',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
    isDark() {
      return this.$q.dark.isActive;
    },
  },
  watch: {
    news(oldval, newval) {
      console.log('oldval', oldval);
      console.log('newval', newval);
    },
  },
  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
    }),
    i18n(code) {
      return i18n(code);
    },
  },
};
