import { render, staticRenderFns } from "./ad-space.vue?vue&type=template&id=d175cdfa&scoped=true&"
import script from "./ad-space.vue?vue&type=script&lang=js&"
export * from "./ad-space.vue?vue&type=script&lang=js&"
import style0 from "./ad-space.vue?vue&type=style&index=0&id=d175cdfa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d175cdfa",
  null
  
)

export default component.exports
import {QImg,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QImg,QBtn})
