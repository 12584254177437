
import { i18n } from '@/i18n';
import { mapGetters } from 'vuex';

export default {
  name: 'app-home-sports-page',

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
    isDark() {
      return this.$q.dark.isActive;
    },
  },
  methods: {
    i18n(code) {
      return i18n(code);
    },
  },
};
