import { render, staticRenderFns } from "./5-home-first-team.vue?vue&type=template&id=795bea1e&scoped=true&"
import script from "./5-home-first-team.vue?vue&type=script&lang=js&"
export * from "./5-home-first-team.vue?vue&type=script&lang=js&"
import style0 from "./5-home-first-team.vue?vue&type=style&index=0&id=795bea1e&prod&lang=scss&scoped=true&"
import style1 from "./5-home-first-team.vue?vue&type=style&index=1&id=795bea1e&prod&lang=scss&"
import style2 from "./5-home-first-team.vue?vue&type=style&index=2&id=795bea1e&prod&lang=scss&scoped=true&"
import style3 from "./5-home-first-team.vue?vue&type=style&index=3&id=795bea1e&prod&lang=scss&scoped=true&"
import style4 from "./5-home-first-team.vue?vue&type=style&index=4&id=795bea1e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "795bea1e",
  null
  
)

export default component.exports
import {QSeparator,QImg,QCard,QCardSection,QIcon,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSeparator,QImg,QCard,QCardSection,QIcon,QBtn})
