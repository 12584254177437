
import { mapActions, mapGetters } from 'vuex';
import { i18n } from '@/i18n';
import moment from 'moment';
import AdSpace from '@/modules/home/components/ad-space.vue';

export default {
  name: 'app-home-matches-page',
  components: {
    [AdSpace.name]: AdSpace,
  },
  data() {
    return {
      slide: 1,
      countDownDate: 'jun 16, 2024 17:00:00',
      now: new Date().setHours(0, 0, 0, 0),
      matches: [
        {
          matchType: 'football',
          matchDate: 'Feb 26, 2023 17:00:00',
          teamLogo:
            'https://ssl.gstatic.com/onebox/media/sports/logos/XJ4jlwtl00P-7y4b0hj2Vg_96x96.png',
          teamName: {
            en: 'Haras El Hodood',
            ar: 'حرس الحدود',
          },
        },

        {
          matchType: 'football',
          matchDate: 'Mar 11, 2023 19:00:00',
          teamLogo:
            'https://ssl.gstatic.com/onebox/media/sports/logos/Ra_Fuh-xdYTK41_UUigPVA_48x48.png',
          teamName: {
            en: 'El Mokawloon',
            ar: 'المقاولون',
          },
        },
        {
          matchType: 'football',
          matchDate: 'Mar 16, 2023 19:00:00',
          teamLogo:
            'https://ssl.gstatic.com/onebox/media/sports/logos/CEExkv5QDXXiJLhN6UgqaQ_48x48.png',
          teamName: {
            en: 'El ismaily',
            ar: 'الأسماعيلي',
          },
        },
        {
          matchType: 'football',
          matchDate: 'Apr 3, 2023 21:00:00',
          teamLogo: '/images/aswan-club.svg',
          teamName: {
            en: 'Aswan SC',
            ar: 'اسوان',
          },
        },
        {
          matchType: 'football',
          matchDate: 'Apr 8, 2023 21:00:00',
          teamLogo:
            'https://ssl.gstatic.com/onebox/media/sports/logos/JH5f6YygHFwI4UNhcOFDJQ_48x48.png',
          teamName: {
            en: 'El Dakhliya',
            ar: 'الداخلية',
          },
        },
        {
          matchType: 'football',
          matchDate: 'Apr 15, 2023 21:00:00',
          teamLogo:
            'https://ssl.gstatic.com/onebox/media/sports/logos/T5_q5gr5Tdu9pPTm-x6-UA_48x48.png',
          teamName: {
            en: 'El ittihad',
            ar: 'الاتحاد',
          },
        },
        {
          matchType: 'football',
          matchDate: 'Apr 18, 2023 21:00:00',
          teamLogo: '/images/ahly-club.svg',
          teamName: {
            en: 'Al Ahly',
            ar: 'الأهلى',
          },
        },
        {
          matchType: 'football',
          matchDate: 'Apr 26, 2023 16:00:00',
          teamLogo: '/images/Ghazl-elmahala.svg',
          teamName: {
            en: 'Ghazl el mahalla',
            ar: 'غزل المحلة',
          },
        },
      ],
      validMatches: [],
    };
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
    isLight() {
      return this.$q.dark.isActive;
    },
    options() {
      return {
        rewind: true,
        // width: 800,
        // arrowPath: '/images/icons/arrow-right.svg',
        // arrows: false,
        // padding: this.is_screen_xs ? '0': '5rem',
        gap: '1.25rem',
        perPage: 2,
        // direction: 'rtl',
        breakpoints: {
          640: {
            // destroy: true,
            perPage: 1,
            gap: '1rem',
          },
          480: {
            perPage: 1,
            gap: '1rem',
          },
        },
      };
    },
  },
  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
    }),
    i18n(code) {
      return i18n(code);
    },
    displayDate(date) {
      return date
        ? moment(date)
            .locale(this.currentLanguageCode)
            .format('D MMMM')
        : 'ـــ';
      // return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ';
    },
    doFetchNextDate() {
      console.log('doFetchNextDate');
    },
  },
  created() {
    this.countDownDate = this.matches.filter((e) => {
      return moment(e.matchDate) > this.now;
    })[0].matchDate;

    this.validMatches = this.matches.filter((e) => {
      return moment(e.matchDate) > this.now;
    });
  },
};
