import { render, staticRenderFns } from "./6-home-shop.vue?vue&type=template&id=511e8da4&scoped=true&"
import script from "./6-home-shop.vue?vue&type=script&lang=js&"
export * from "./6-home-shop.vue?vue&type=script&lang=js&"
import style0 from "./6-home-shop.vue?vue&type=style&index=0&id=511e8da4&prod&lang=scss&scoped=true&"
import style1 from "./6-home-shop.vue?vue&type=style&index=1&id=511e8da4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511e8da4",
  null
  
)

export default component.exports
import {QSeparator,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSeparator,QBtn})
