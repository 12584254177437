
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'app-ad-space',
  props: ['position'],
  computed: {
    ...mapGetters({
      // currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      ads: 'layout/ads',
    }),
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
  },
  methods: {
    ...mapActions({
      toggleMenu: 'layout/toggleMenu',
      doFetchAds: 'layout/doFetchAds',
      doUpdateViews: 'layout/doUpdateViews',
    }),
    async updateViews() {
      const myIp = await fetch(
        'https://api.ipify.org?format=json',
      ).then((x) => x.json());
      if (this.ads[0].position == this.position) {
        await this.doUpdateViews({
          id: this.ads[0].id,
          ip: myIp.ip,
        });
        window.location.href = this.ads[0].link;
      } else {
        await this.doUpdateViews({
          id: this.ads[1].id,
          ip: myIp.ip,
        });
        window.location.href = this.ads[1].link;
      }
      this.$emit('adclicked');
    },
  },
  async created() {
    await this.doFetchAds('homePage');
    // console.log('this is ads ', this.ads)
  },
};
