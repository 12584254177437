
import { i18n } from '@/i18n';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'app-home-shop-page',

  data() {
    return {
      items: [
        {
          name: {
            en: 'Smouha SC  Shirt',
            ar: 'قميص سموحة',
          },
          photoUrl: '/images/smouha-shirt.webp',
          url: '',
        },
        {
          name: {
            en: 'Smouha SC Cap',
            ar: 'كاب سموحة',
          },
          photoUrl: '/images/smouha-cap.webp',
          url: '',
        },
        {
          name: {
            en: 'Smouha SC Shoes',
            ar: 'أحذية سموحة',
          },
          photoUrl: '/images/smouha-snickers.webp',
          url: '',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
  },
  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
    }),
    i18n(code) {
      return i18n(code);
    },
  },
};
