
import { i18n } from '@/i18n';
import { mapGetters } from 'vuex';

export default {
  name: 'app-home-first-team-page',
  computed: {
    ...mapGetters({
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
  },

  data() {
    return {
      // index: 1,
      active: window.innerWidth < 768 ? 0 : 1,
      options: {
        perView: 4,
        type: 'slider',
        // type: 'carousel',
        focusAt: window.innerWidth < 768 ? 0 : 1,
        // focusAt: 'center',
        gap: 50,
        startAt: 1,
        swipeThreshold: 400,
        touchRatio: 0.2,

        // swipeThreshold: 500,
        autoplay: false,
        // bound: true,
        // rewind: false,
        peek: { before: 150, after: 50 },
        breakpoints: {
          1024: {
            perView: 3,
          },
          800: {
            perView: 2,
          },
          500: {
            perView: 1,
          },
        },
      },

      teamPlayers: [
        //#region [ Goalkeeper ]
        {
          number: 1,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Junior ajayi',
            ar: 'جونيور اجاي',
          },
          firstName: {
            en: 'Junior',
            ar: 'جونيور',
          },
          lastName: {
            en: 'Ajayi',
            ar: 'اجاي',
          },
          position: {
            en: 'Forward',
            ar: 'مهاجم',
          },
        },
        {
          number: 21,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Omar Salah',
            ar: 'عمر صلاح',
          },
          firstName: {
            en: 'Omar',
            ar: 'عمر',
          },
          lastName: {
            en: 'Salah',
            ar: 'صلاح',
          },
          position: {
            en: 'Goalkeeper',
            ar: 'حارس مرمى',
          },
        },
        //#endregion

        //#region [ Defender ]
        {
          number: 20,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Ahmed Jamal',
            ar: 'أحمد جمال',
          },
          firstName: {
            en: 'Ahmed',
            ar: 'أحمد',
          },
          lastName: {
            en: 'Jamal',
            ar: 'جمال',
          },
          position: {
            en: 'Defender',
            ar: 'مدافع',
          },
        },
        {
          number: 5,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Hossam Hassan',
            ar: 'حسام حسن',
          },
          firstName: {
            en: 'Hossam',
            ar: 'حسام',
          },
          lastName: {
            en: 'Hassan',
            ar: 'حسن',
          },
          position: {
            en: 'Forward',
            ar: 'مهاجم',
          },
        },
        {
          number: 4,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Rajab Nabeel',
            ar: 'رجب نبيل',
          },
          firstName: {
            en: 'Rajab',
            ar: 'رجب',
          },
          lastName: {
            en: 'Nabeel',
            ar: 'نبيل',
          },
          position: {
            en: 'Defender',
            ar: 'مدافع',
          },
        },
        {
          number: 13,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Mahmoud Al-Badri',
            ar: 'محمود البدري',
          },
          firstName: {
            en: 'Mahmoud',
            ar: 'محمود',
          },
          lastName: {
            en: 'Al-Badri',
            ar: 'البدري',
          },
          position: {
            en: 'Defender',
            ar: 'مدافع',
          },
        },
        {
          number: 2,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Hussian Faisal',
            ar: 'حسين فيصل',
          },
          firstName: {
            en: 'Hussian',
            ar: 'حسين',
          },
          lastName: {
            en: 'Faisal',
            ar: 'فيصل',
          },
          position: {
            en: 'Midfielder',
            ar: 'خد وسط',
          },
        },
        {
          number: 66,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Mostafa Saad',
            ar: 'محمد عبد المنعم',
          },
          firstName: {
            en: 'Mostafa',
            ar: 'مصطفي',
          },
          lastName: {
            en: 'Saad',
            ar: 'سعد',
          },
          position: {
            en: 'Midfielder',
            ar: 'خد وسط',
          },
        },
        {
          number: 26,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Athar Al-Taher',
            ar: 'أطهر الطاهر',
          },
          firstName: {
            en: 'Athar',
            ar: 'أطهر',
          },
          lastName: {
            en: 'Al-Taher',
            ar: 'الطاهر',
          },
          position: {
            en: 'Defender',
            ar: 'مدافع',
          },
        },
        {
          number: 31,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Faris Mujahid',
            ar: 'فارس مجاهد',
          },
          firstName: {
            en: 'Faris',
            ar: 'فارس',
          },
          lastName: {
            en: 'Mujahid',
            ar: 'مجاهد',
          },
          position: {
            en: 'Defender',
            ar: 'مدافع',
          },
        },
        //#endregion

        //#region [ Midfielder ]
        // {
        //   number: 0,
        //   photo: '/images/default/player-avatar.webp',
        //   name: {
        //     en: '',
        //     ar: '',
        //   },
        //   firstName: {
        //     en: '',
        //     ar: '',
        //   },
        //   lastName: {
        //     en: '',
        //     ar: '',
        //   },
        //   position: {
        //     en: 'Midfielder',
        //     ar: 'لاعب وسط',
        //   },
        // },
        //#endregion

        //#region [ Striker ]
        {
          number: 18,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Hossam Hassan',
            ar: 'حسام حسن',
          },
          firstName: {
            en: 'Hossam',
            ar: 'حسام',
          },
          lastName: {
            en: 'Hassan',
            ar: 'حسن',
          },
          position: {
            en: 'Striker',
            ar: 'مهاجم',
          },
        },
        {
          number: 19,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Derek Nesibambi',
            ar: 'ديريك نيسيبامبي',
          },
          firstName: {
            en: 'Derek',
            ar: 'ديريك',
          },
          lastName: {
            en: 'Nesibambi',
            ar: 'نيسيبامبي',
          },
          position: {
            en: 'Striker',
            ar: 'مهاجم',
          },
        },
        {
          number: 8,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Rafiq Kabo',
            ar: 'رفيق كابو',
          },
          firstName: {
            en: 'Rafiq',
            ar: 'رفيق',
          },
          lastName: {
            en: 'Kabo',
            ar: 'كابو',
          },
          position: {
            en: 'Striker',
            ar: 'مهاجم',
          },
        },
        {
          number: 7,
          photo: '/images/default/player-avatar.webp',
          name: {
            en: 'Abdul Kabir Al-Wadi',
            ar: 'عبد الكبير الوادي',
          },
          firstName: {
            en: 'Abdul Kabir',
            ar: 'عبد الكبير',
          },
          lastName: {
            en: 'Al-Wadi',
            ar: 'الوادي',
          },
          position: {
            en: 'Striker',
            ar: 'مهاجم',
          },
        },
        //#endregion
      ],
    };
  },

  methods: {
    i18n(code) {
      return i18n(code);
    },
    playerMove(index) {
      console.log('player index = ', index);
    },
  },
};
